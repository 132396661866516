import React, { useState, useEffect, useCallback,createRef } from 'react';
import MaterialIcon from '@material/react-material-icon';
import './Ajax.scss';
import I18N from '../I18N/I18n';


function AjaxSearchBox({res, query, mydata, handleSubmit, handleKeyPress, search, onChange})
{
  const inputRef = React.useRef();
  const [toggle, setToggled] = useState(false);

  useEffect( () => {
    inputRef && inputRef.current && inputRef.current.inputElement && inputRef.current.inputElement_.current && inputRef.current.inputElement_.current.focus();      
  },[toggle]);

  return (
    <div className={"tiny ajax"}>
          <div style={{
            display: toggle ? "block" : "none"
          }}>
    
          <div className="row big-ajax">
            <div className="ajax searcher">
            <form action={mydata.config.searchpage} onSubmit={handleSubmit} onKeyUp={handleKeyPress} autoComplete="off">
            <div className="controls">
              <div className="text">
              <label>{I18N("label")}</label>
              <input
                type="text"
                value={ query}
                handleKeyPress={handleKeyPress}
                onChange={onChange} />  
                </div>
                <input
                  type="submit" value="search" />  
                </div>              
            </form>              
            { res }
          </div>
        </div>

          </div>
          <div className="ju-search-button" onClick={() => { setToggled(!toggle); }}>
            <span className="icon">{toggle ? "close" : "search"}</span>
          </div>
    </div>
  );
}

export default AjaxSearchBox;