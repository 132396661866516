import xi18nsv from '/sitevision/i18n/sv.json';
import xi18nen from '/sitevision/i18n/en.json';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { LanguageContext } from "../I18N/LanguageContext.js"

function I18N(t)
{
  const lang = useContext(LanguageContext);
  const tr = lang == "sv" ? xi18nsv[t] ? xi18nsv[t] : t : xi18nen[t] ? xi18nen[t] : t;
  return tr;
}


export default I18N;