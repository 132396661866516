import React, { useState, useEffect, useCallback } from 'react';
import '@material/react-list/dist/list.css';
import "@material/react-checkbox/dist/checkbox.css";
import '@material/react-card/dist/card.css';
import '@material/react-button/dist/button.css';
import Button from '@material/react-button';
import MaterialIcon from '@material/react-material-icon';
import '@material/react-layout-grid/dist/layout-grid.css';

import SearchRes from '../Search/SearchResults';



function RelatedFetcher({ mydata })
{
  const [data, setData] = useState([]);
  const hits = data && data.hits && data.hits.hits.map((hit, i) => (<SearchRes key={"search" + i} hit={hit}></SearchRes>));

  useEffect(() => {
    const params = {
      "sign": mydata.params.sign,
      "id": mydata.params.id
    }
    const purl = new URLSearchParams(params).toString();
    fetch(mydata.searchurl["/related"]+"?"+purl)
      .then(res => res.json())
      .then(body => {
        setData(body);
      });
  }, []);

  return (
    <div className="statuspage">
      <div>
        <h2 id="showmore-Steg1-Hurvilldubo?" data-content="Steg 1 - Hur vill du bo?" class="ju-showmore-v2 activated" aria-expanded="true" tabindex="0">
          { I18N("related") }
        </h2>
      </div>
      <div className="related hidden">
        {hits}
      </div>
    </div>);
}

export default RelatedFetcher;