import React, { useState, useEffect, useContext, useCallback } from 'react';
import SearchInterface from './SearchInterface';
import AjaxFetcher from '../Ajax/AjaxFetcher';
import PersonSearch from '../Person/PersonSearch';

function SearchFetcher({ mydata }) {
  const [query, setQuery] = useState(mydata.params.query ? mydata.params.query : "");
  const [page, setPage] = useState(0);
  const [aggregations, setAggregations] = useState(
    mydata.facets.current
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    const aggs = Object.assign({}, ...Object.keys(aggregations).map(k => {
      return { [k]: aggregations[k].length ? aggregations[k].join("|") : [] };
    }).filter(agg => agg));

    const params = {
      ...aggs,
      "query": query,
      "page": page
    };
    const purl = new URLSearchParams(params).toString();
    try {
      fetch(mydata.searchurl["/search"] + "?" + purl).then(response => response.json()).then(function (ab) {
          setData(ab);
      });
    } catch (ex) {


        }
    history.pushState(params, null, "?" + purl);
  }, [query, page, JSON.stringify(aggregations)]);

  window.onpopstate = function (event) {
    try {
      if (event.state.query) {
        setQuery(event.state.query);
        setAggregations(event.state.aggregations);
      }
    } catch (ex) { }
  }

  const setAggregation = (key, value) => {
    var cur = aggregations[key];
    const ii = cur && cur.indexOf(value);
    if (cur) {
      const cf = cur.filter(c => c != "*");
      if (ii == -1) {
        cf.push(value);
      } else {
        cf.splice(ii, 1);
      }
      setAggregations({
        ...aggregations,
        [key]: cf.length == 0 ? ["*"] : cf
      });
      setPage(0);
    } else {
      setAggregations({
        ...aggregations,
        [key]: [value]
      });
      setPage(0);
      console.log("key:" + key + " not found");
    }
  };

  return (
    <>
      <AjaxFetcher mydata={mydata}
        inquery={query}
        setSearchQuery={(q) => {
          setQuery(q);
          setAggregations(mydata.facets.default);
        }} />
      <SearchInterface mydata={mydata}
        data={data}
        query={query}
        page={page}
        personres={(mydata.staff == "hide" ? "" : <PersonSearch url={mydata.searchurl} query={query}  />)}
        setPage={setPage}
        setQuery={setQuery}
        aggregations={aggregations}
        setAggregation={setAggregation}
      />
    </>);
}

export default SearchFetcher;