import React, { useState, useEffect, useCallback } from 'react';

import PersonResults from './PersonResults';

import I18N from '../I18N/I18n';

function PersonSearch({ query, url }) {
  const [limit, setLimit] = useState(4);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLimit(4);
  }, [query]);

  useEffect(() => {
    try {
      const purl = new URLSearchParams({
        "query": query,
        "limit": limit,
      }).toString();

      fetch(url["/person"]+"?"+purl).then(response => response.json())
        .then(function (ab) {
          setData(ab);
        });
    } catch (ex) {
    }
  }, [query, limit]);
  
  return (
    <PersonResults data={data} limit={limit} setLimit={setLimit} />
  );
}
export default PersonSearch;