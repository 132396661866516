import React, { useState, useEffect, useCallback } from 'react';
import BigAjaxSearch from './BigAjaxSearch';
import AjaxSearchBox from './AjaxSearchBox';
import AjaxSearchBoxJu from './AjaxSearchBoxJu';
import AjaxResults from './AjaxResults';

function AjaxFetcher({ inquery, setSearchQuery = null, mydata }) {
  const [query, setQuery] = useState(mydata.params.query ? mydata.params.query  : "");
  const [data, setData] = useState([]);
  const [sel, setSel] = useState(-1);
  const [active, setActive] = useState(false);

  const onChange = (event) => {
    setQuery(event.target.value);
    setActive(true);
  };


  useEffect(() => {
    if( inquery ) {
      setQuery(inquery);
    }
  }, [inquery]);

  const search = (q) => {
    if (setSearchQuery) {
      setSearchQuery(q);
      setQuery(q);
      setActive(false);
    } else {
      document.location = (mydata.locale == "en" ? mydata.config.enSearchPage : mydata.config.searchpage) + "?query=" + q
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'ArrowUp') {
      setSel(sel - 1);
    }
    if (event.key === 'ArrowDown') {
      setSel(sel + 1);
    }
    if (event.key === 'Enter') {
      if (setSearchQuery) {
        setActive(false);
      }
    }
    else if (event.key === 'Backspace') {
      setActive(false);
    } else {
      setActive(true);
    }
  }

  const handleSubmit = (event) => {
    if (sel != -1 && data && data.hits.hits && data.hits.hits[sel] && data.hits.hits[sel]._source) {
      search(data.hits.hits[sel]._source.autocomplete);
    } else {
      search(query);
    }
    setSel(-1);
    setActive(false);
    event.preventDefault();
    return false;
  }

  useEffect(() => {
    const params = {
      "query": query,
    }
    const purl = new URLSearchParams(params).toString();

    fetch(mydata.searchurl["/ajax"] + "?" + purl).then(response => response.json())
      .then(function (ab) {
        setData(ab);
      });
  }, [query]);

  const res = query && active ? (<AjaxResults
    data={data}
    searchpage={mydata.config.searchpage}
    sel={sel}
    search={search}
    query={query}>
  </AjaxResults>) : ("");

  return mydata.template == "ajax3" ? (
    <AjaxSearchBoxJu mydata={mydata} active={active} query={query}
      handleKeyPress={handleKeyPress}
      search={search}
      onChange={onChange}
      handleSubmit={handleSubmit}
      res={res}
    />) : mydata.template == "ajax4" ? (
      <AjaxSearchBox mydata={mydata} active={active} query={query}
        handleKeyPress={handleKeyPress}
        search={search}
        onChange={onChange}
        handleSubmit={handleSubmit}
        res={res}
      />
  ) : mydata.template == "ajax2" ? (
    <BigAjaxSearch mydata={mydata} active={active} query={query}
      handleKeyPress={handleKeyPress}
      search={search}
      onChange={onChange}
      handleSubmit={handleSubmit}
      res={res}
    />
  ) : (
    <BigAjaxSearch mydata={mydata} active={active} query={query}
      handleKeyPress={handleKeyPress}
      search={search}
      onChange={onChange}
      handleSubmit={handleSubmit}
      res={res}
    />
  );
}

export default AjaxFetcher;