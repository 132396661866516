import React, { useState, useEffect, useCallback } from 'react';
import '@material/react-card/dist/card.css';
import MaterialIcon from '@material/react-material-icon';
import List, { ListItem, ListItemGraphic, ListItemText } from '@material/react-list';
import {
  CardPrimaryContent,
  CardMedia,
  CardActions,
} from "@material/react-card";
import { Cell, Grid, Row } from '@material/react-layout-grid';
import Button from '@material/react-button';

import I18N from '../I18N/I18n';
import './Person.scss';

function getVisaWebb(settings) {
	return ["photo", "addr", "hemtele", "privattele"].reduce((result, filter, i) => {      
	    result[filter] = settings ? ((settings >> (3*i) & 4) >> 2) : 0;
	    return result;
	},{});
}

function PersonRes({ hit }) {
  const visa = getVisaWebb(hit._source.person_showsettings);
  const image = visa.photo && hit._source.photo_thumbnail ? "data:image/jpeg;base64,"+hit._source.photo_thumbnail : "https://ju.se/images/18.2db53140171ba316558217c8/1588586761505/JU_ers%C3%A4ttningsbild_personals%C3%B6k_2020.jpg";
  
  return (
    <Cell className={"mdc-card person"} columns={4}>
      <a href={"/personinfo.html?id=" + hit._source.personid}>
        <CardPrimaryContent>
          <CardMedia imageUrl={image} />
          <h3>{hit._source.firstname} {hit._source.familyname}</h3>
          <div className="text" dangerouslySetInnerHTML={{
            __html: hit.highlight && hit.highlight.body ? hit.highlight.body : hit._source && hit._source.body && ("" + hit._source.body).substr(0, 250)
          }}>
          </div>
          <List>
            {hit._source[I18N("professionaltitle_field")] &&
              (<li className="title"><div>{
                hit._source[I18N("professionaltitle_field")].map(t => {
                  return (
                    <ListItem>
                      <ListItemGraphic graphic={<MaterialIcon icon='business_center' />} />
                      <ListItemText primaryText={t} />
                    </ListItem>
                  );
                })
              }</div></li>)
            }
            {hit._source[I18N("academictitle_field")] && (
              <ListItem>
                <ListItemGraphic graphic={<MaterialIcon icon='school' />} />
                <ListItemText primaryText={hit._source[I18N("academictitle_field")]} />
              </ListItem>)
            }

            {hit._source.phone && (<ListItem>
              <ListItemGraphic graphic={<MaterialIcon icon='phone' />} />
              <ListItemText primaryText={hit._source.phone} />
            </ListItem>)}
          </List>

        </CardPrimaryContent>
      </a>
      <CardActions>
      </CardActions>
    </Cell>);
}


function PersonResults({ data, limit, setLimit }) {
  const nrpersonhits = data && data.personres && data.personres.hits && data.personres.hits.hits.length;
  const personres = data && data.personres && data.personres.hits &&  (limit == 4 ? data.personres.hits.hits.slice(0, 3) : data.personres.hits.hits);
  const personhits = personres && personres.map( (hit,i) => (<PersonRes key={i}  hit={hit}></PersonRes>));

  return (
    <Grid className="persons">
      <Row>
        {personhits}
      </Row>
      <Row>
        <Cell columns={12} className={"center"}>
          {
            limit == 4 && personhits && nrpersonhits > 3 ? (
            <Button raised onClick={() => setLimit(40)} icon={<MaterialIcon icon='expand_more' />}>{ I18N('Show more staff') }</Button>
            ) : limit == 40 ? (
            <Button raised onClick={() => setLimit(4)} icon={<MaterialIcon icon='expand_less' />}>{ I18N('Show less staff') }</Button>) : (" ")
          }
        </Cell>
      </Row>
    </Grid>
  );
}
export default PersonResults;