import React, { useState, useEffect, useCallback } from 'react';
import '@material/react-list/dist/list.css';
import "@material/react-checkbox/dist/checkbox.css";
import Checkbox from '@material/react-checkbox';
import '@material/react-card/dist/card.css';
import '@material/react-button/dist/button.css';
import Button from '@material/react-button';
import Card, {
  CardPrimaryContent,
  CardActions,
} from "@material/react-card";
import '@material/react-layout-grid/dist/layout-grid.css';
import '@material/react-card/dist/card.css';
import MaterialIcon from '@material/react-material-icon';
import I18N from '../I18N/I18n';
import List, { ListItem, ListItemGraphic, ListItemText } from '@material/react-list';

function OsearchRes({ hit }) {
  const crumbs = hit && hit._source && hit._source.breadcrumblinks && hit._source.breadcrumblinks.map((v, i) => {
    return (i > 0) ?  (<li key={i}>
      <a className={"normal"} href={hit._source && hit._source.breadcrumblinks[i] && hit._source.breadcrumblinks[i].replace(/.*\/\/[^\/]*/, '')}>{hit._source.breadcrumbtitles[i]}</a>
    </li>) : (
      <li>
      <a className={"normal"}  href="">
        { hit._source.contentgroup && hit._source.contentgroup.length ? I18N(hit._source.contentgroup[0]) :"" }
      </a>
      </li>
    );
  });

  return (
      <Card>
       <a href={hit._source && hit._source.url && hit._source.url.replace(/.*\/\/[^\/]*/, '')} className="name">
          <CardPrimaryContent>
            <h3>
                <span dangerouslySetInnerHTML={{
                  __html: hit && hit.highlight && hit.highlight.title ? hit.highlight.title :
                    hit && hit._source && hit._source.title ? hit._source.title :
                    hit && hit._source && hit._source.titles && hit._source.titles.length ? hit._source.titles[0] : ""                
                }} className="name">
                </span>
            </h3>
            <div className="text" dangerouslySetInnerHTML={{
              __html:
                hit.highlight && hit.highlight.body ? hit.highlight.body :
                  hit._source && hit._source.body && ("" + hit._source.body).substr(0, 250)
            }}>
            </div>
          </CardPrimaryContent>
      </a>
      { crumbs ?
        (<CardActions>
          <nav className="search-breadcrumbs" aria-label="breadcrumbs">
            <ol>
              {crumbs}
            </ol>
          </nav>
        </CardActions>) : "" 
      }    
      </Card>
  );
}
function FeaturedHit({hit, icon, tag, children}) {
  return (<a className="featuredhit" href={hit._source.url && hit._source.url.replace(/.*\/\/[^\/]*/, '')}>
        <div className="icon"> {icon}</div>
        <div className="cntent">
        <a href={hit._source.url && hit._source.url.replace(/.*\/\/[^\/]*/, '')} className="name">          
              <h3><span dangerouslySetInnerHTML={{
                __html: hit && hit.highlight && hit.highlight.title ? hit.highlight.title :
                  hit && hit._source && hit._source.title ? hit._source.title :
                  hit && hit._source && hit._source.titles && hit._source.titles.length ? hit._source.titles[0] : ""                
              }} className="xname">
              </span>
              { tag  ? <span className="tag">{tag}</span> : "" }
              </h3>
        </a>
            {children}        
      </div>
    </a>
    );
}

function ProgrammeSearchRes({ hit }) {
  return <FeaturedHit hit={hit} tag={ hit._source.omfattning + "hp"} icon={<MaterialIcon icon='school' />}>    
    <ul>
      {hit._source.examensBenamning && <li dangerouslySetInnerHTML={{__html: hit._source.examensBenamning}}></li>}
      {/* <li><MaterialIcon icon='place' /> { [...new Set(hit._source.ort)].sort().join(", ") }</li> */}
      <li><MaterialIcon icon='place' /> { hit._source.ort }</li>
    </ul>
  </FeaturedHit>
}

function CalendarSearchRes({ hit }) {
  return <FeaturedHit hit={hit} icon={<MaterialIcon icon='event' />}>
      <ul>
        <li>{ hit._source.dateplace }</li>
      </ul>
  </FeaturedHit>
}

function CourseSearchRes({ hit }) {
  return <FeaturedHit hit={hit} tag={ hit._source.omfattning + "hp"} icon={<MaterialIcon icon='menu_book' />}>    
    <ul>
      <li> { [...new Set(hit._source.termin.map( t => {
              return (I18N("TERMIN_"+t.substr(4,1))+" "+t.substr(0,4));
          }))].sort().join(",") }</li>
      <li>
        {/* <MaterialIcon icon='place' /> { [...new Set(hit._source.ort)].sort().join(", ") } */}
        <MaterialIcon icon='place' /> {hit._source.ort}
        </li>
    </ul>
  </FeaturedHit>
}



function SearchRes({ hit }) {
  const crumbs = hit && hit._source && hit._source.breadcrumblinks && hit._source.breadcrumblinks.map((v, i) => {
    return (<li key={i}>
      <a className={"normal"} href={hit._source.breadcrumblinks[i]}>{hit._source.breadcrumbtitles[i]}</a>
    </li>);
  });

  return hit._source.type && hit._source.type=="course" ? (
    <CourseSearchRes hit={hit}/>
  ) : hit._source.type && hit._source.type=="program" ? (
    <ProgrammeSearchRes hit={hit}/>
  ) : hit._source.type && hit._source.type=="calendar" ? (
    <CalendarSearchRes hit={hit}/>
  ) :(
    <OsearchRes hit={hit}/>
  );
}



export default SearchRes;