import React, { useState, useEffect } from 'react';
import './Ajax.scss';
import I18N from '../I18N/I18n';


function AjaxSearchBox({res, query, mydata, handleSubmit, handleKeyPress, search, onChange})
{
  const inputRef = React.useRef();
  const [toggle, setToggled] = useState(false);

  useEffect( () => {
    inputRef && inputRef.current && inputRef.current.inputElement && inputRef.current.inputElement_.current && inputRef.current.inputElement_.current.focus();      
  },[toggle]);

  return (<><div id="SearchAJAX" tabindex="0"></div>
      <div id="ju-search-top" class={toggle?"active":"inactive"}>
      <form class="sv-nomargin sv-searchform-portlet" action={mydata.locale=="en" ? mydata.config.enSearchPage : mydata.config.searchpage} onSubmit={handleSubmit} onKeyUp={handleKeyPress} method="get">
          <input title="Ange sökord" id="search-box-query" type="text" value={query} autoComplete="off"  name="query" aria-label="Sökord"  onChange={onChange} 
          placeholder={I18N("Search")} 
          handleKeyPress={handleKeyPress}
          ref={inputRef}/>
          <input title="Search" type="submit" value="Search" id="searchbutton"/>
            { 
              res ? 
              <div class="mincontent"><div><div id="searchResults">{res}</div>
              </div>
            </div> : ""
            }
      </form>
   </div>
   </>
  );
}

export default AjaxSearchBox;