import React, { useState, useEffect, useCallback } from 'react';
import List, { ListItem, ListItemGraphic, ListItemText, ListItemMeta } from '@material/react-list';
import MaterialIcon from '@material/react-material-icon';
import I18N from '../I18N/I18n';

function SearchRes({ search, selected, hit }) {
  return (<a onClick={() => search(hit._source.autocomplete)}
    onKeyDown={(event) => {
      (event.key === 'Enter') && search(hit._source.autocomplete)
    }} className="name">
    <ListItem className={selected ? "selected" : ""}>
      <span dangerouslySetInnerHTML={{
        __html: hit && hit.highlight && hit.highlight.autocomplete ? hit.highlight.autocomplete :
          hit && hit._source && hit._source.autocomplete
      }}></span>
    </ListItem>
  </a>
  );
}

function AjaxResults({ query, sel = -1, data, search }) {
  const firsthits = data && data.hits && data.hits.hits.map((hit, i) => (<SearchRes selected={i == sel} key={i} search={search} hit={hit}></SearchRes>));
  return (<List>
    {firsthits}
    <a onClick={() => search(query)}
      onKeyDown={(event) => {
        (event.key === 'Enter') && search(query)
      }
      }
      className="name">
      <ListItem className="ajaxaction">
        <ListItemGraphic graphic={<MaterialIcon icon='search' />} />
        <ListItemText primaryText={I18N("Search for") + " " + query} />
      </ListItem>
    </a>
  </List>);
}


export default AjaxResults;