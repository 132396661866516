import React, { useState, useEffect, useCallback }  from 'react';
import SearchRes from './SearchResults';
import InvertedResult from './InvertedResult';
import '@material/react-button/dist/button.css';

import axios from 'axios';
import { Cell, Grid, Row } from '@material/react-layout-grid';

import "@material/react-chips/dist/chips.css";
import { Chip } from '@material/react-chips';
import Aggregations from './Aggregations';
import Pagination from './Pagination';
import MaterialIcon from '@material/react-material-icon';
import Button from '@material/react-button';

import I18N from '../I18N/I18n';

function DidYouMean({ data, query, setQuery }) {
  const firsthits = data && data.searchres && data.searchres.suggest && data.searchres.suggest.simple_phrase.filter(h => h.options && h.options.length).map(hit => {
    return (
      <Chip className="primary" leadingIcon={<MaterialIcon icon={"search"} />} onClick={() => setQuery(hit.options[0].text)} label={hit.options[0].text}></Chip>)
    });

  return firsthits && firsthits.length ? (<>
    <h2>{I18N("did you mean")}</h2>
    {firsthits}
  </>) : "";
}

function MobileAggregations({data, aggregations, setAggregations})
{
  const [show, setShow] = useState(false);

  return ( 
    <div>
      <Button className="refine" raised onClick={() => setShow( !show ) } icon={<MaterialIcon icon='expand_more' />}>{ I18N('refine search results') }</Button>
      { show ? ( <Aggregations
        data={data}
        aggregations={aggregations}
        setAggregations={setAggregations} />) : ""}
    </div>
  )
}

function WhatDoYouThink({query,locale, aggregations}) {
  const [show, setShow] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [thumbs, setThumbs] = useState("false");
  const [comment, setComment] = useState("");

  const onChange = (event) => {
    setComment(event.target.value);
  };

  const aggs = Object.assign({}, ...Object.keys(aggregations).map(k => {
    return { [k]: aggregations[k].join("|") };
  }).filter(agg => agg));
  var agurl = "?"+Object.keys(aggs).map( (a,k) => ""+a+"="+aggs[a]).join("&");

  const onSubmit = () => {
    let formData = new FormData();
    formData.append("mail_name_1a0fad861526c03449029f5c", "" );
    formData.append("field_120_1a0fad861526c03449029f5f", "Thumbs:"+thumbs+"\n"+
    "Comment:"+comment+"\n"+
    "query:"+query+"\n"+
    "aggs:"+agurl+"\n"+
    "locale:"+locale+"\n"); //komment
    formData.append("field_120_1a0fad861526c03449029f61", query ); // sökterm
    formData.append("field_120_3f69693e152f349a4d58b2b", "" ); // epost

    axios.post("/fragment/enkater/tyck-till-om-soken.html?sv.url=12.1a0fad861526c03449029f5c&state=create", formData, { headers: { 'content-type': 'multipart/form-data' } })
      .then(response => {
        console.log(response);
      }).catch(error => {
        console.log(error);
    });
      setHidden(true);
  }

  const thumbsClck = (thumb) => {
    if(typeof ga !== "undefined") {
          ga('send', 'event', 'Rating search', thumb, query);
    }
  }

  return !hidden ? (<div className="whatdoyouthink">
    { !show ? 
        (<>
          <div className="label">
            { I18N("What do you think about the search results?")}
          </div>
          <a onClick={ () => { setShow(true); setThumbs("up"); thumbsClck("Thumbs up"); }}><MaterialIcon icon={"thumb_up"}/></a>
          <a onClick={ () => { setShow(false); setThumbs("down"); thumbsClck("Thumbs down"); }}><MaterialIcon icon={"thumb_down"}/></a>
        </>) : (<>     
            <div className="sublabel">
              <div className="label"> { I18N("Leave a comment")} </div>
              <MaterialIcon icon={"close"} onClick={ () => setHidden(true) }/>
            </div>            
            <div>
              <textarea type="text"  value={comment} onChange={onChange}/>
              <input type="submit" className="knapp" onClick={ () => onSubmit() } value={I18N("submit")}/>
            </div>
        </>) }
  </div>) : ("");
}

function SearchInterface({ query, template, mydata, data, personres, page, setPage, aggregations, setQuery, setAggregation }) {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const firsthits = data && data.searchres && data.searchres.hits && data.searchres.hits.hits.slice(0, 2).map((hit, i) => (<SearchRes key={"search" + i} hit={hit}></SearchRes>));
  const morehits = data && data.searchres && data.searchres.hits && data.searchres.hits.hits.slice(2).map((hit, i) => (<SearchRes key={"bsearch" + i} hit={hit}></SearchRes>));

  return (<div id="sokreact" className={template}>
    <Grid>

    { aggregations["type.keyword"] && aggregations["type.keyword"].length == 1 && aggregations["type.keyword"][0] == "person" ? "" :
        (<Row>
          <Cell columns={12}>
            <div className="res">
              <div className="cnt">
                <Chip label={I18N("Search results") + (data && data.searchres && data.searchres.hits && data.searchres.hits.total && data.searchres.hits.total.value) + " " + I18N("results")} />
              </div>
            </div>
          </Cell>
        </Row>)
      }


      { width < 1000 ? (<MobileAggregations
            data={data}
            aggregations={aggregations}
            setAggregations={setAggregation} />): ("")
      }
      
      <Row>
        <Cell columns={width < 1000 ? 12 : 8}>
          <DidYouMean query={query} data={data} setQuery={setQuery} />
          <InvertedResult mydata={mydata} query={query} data={data} />
          {firsthits}
          {personres}
          {morehits}
          <Pagination data={data} page={page} setPage={setPage}></Pagination>
        </Cell>
        { width >= 1000 ? (<Cell columns={4}>
          <Aggregations
            data={data}
            aggregations={aggregations}
            setAggregations={setAggregation} />
        </Cell>): ("")}
      </Row>
    </Grid>
  </div>)
}

export default SearchInterface;