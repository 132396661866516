import React, { useState, useEffect, useCallback } from 'react';
import './Ajax.scss';
import './SearchBox.scss';
import './BigAjaxSearch.scss';
import I18N from '../I18N/I18n';


function BigAjaxSearch({ query, res, mydata, handleSubmit, handleKeyPress, search, onChange})
{
  const [pause, setPause] = useState("pause");
  return (
    <div className="big-ajax row-hero">
      <video className="video" id="videoBackground" preload="auto" width="100%" autoPlay="1" muted="1" loop="1" playsinline="1"
        poster="/images/18.4c1ea10d16f5570836bb471/1577971249663/placeholder.jpg" >
        <source src="/download-streamed/18.7f6408211705d5416c3494c0/hero.webm" type="video/webm"/>
        <source src="/download-streamed/18.7f6408211705d5416c3494c1/hero.mp4" type="video/mp4"/>
      </video>
        <div className={"video-overlay" + (mydata.config && mydata.config.title ? "" : " bottom")} >
              <div className="ajax searcher">
                {mydata.config && mydata.config.title ? (<h1>{mydata.config.title}</h1>) : ""}      
                <form action={mydata.config.searchpage} onSubmit={handleSubmit} onKeyUp={handleKeyPress} autoComplete="off">
                <div className="controls">
                  <div className="text">
                  <label>{I18N("label")}</label>
                  <input
                    type="text"
                    value={ query}
                    handleKeyPress={handleKeyPress}
                    onChange={onChange} />  
                    </div>
                    <input
                      type="submit" value="search" />  
                    </div>              
                </form>              
                { res }
              </div>
        </div>
        <button className="pause" aria-label="Pause" onClick={ () => {
            document.getElementById("videoBackground").paused ? document.getElementById("videoBackground").play() : document.getElementById("videoBackground").pause(); 
            setPause( document.getElementById("videoBackground").paused ? "play_arrow" : "pause")
        }}>
          { pause }
        </button>
    </div>
  );
}


export default BigAjaxSearch;