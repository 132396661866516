import React, { useState, useEffect, useCallback } from 'react';
import SearchInterface from './Search/SearchInterface';
import AjaxFetcher from './Ajax/AjaxFetcher';
import RelatedFetcher from './Related/RelatedFetcher';
import BigAjaxSearch from './Ajax/BigAjaxSearch';
import './App.scss';
import SearchFetcher from './Search/SearchFetcher';
import { LanguageContext } from "./I18N/LanguageContext.js"

function App({ mydata }) {
  const template = mydata.template ? mydata.template : "search";
  console.log(mydata);
  return <LanguageContext.Provider value={mydata.locale}> { 
      template == "related" ? <RelatedFetcher mydata={mydata}/> : 
      template == "ajax" ? <AjaxFetcher mydata={mydata} /> : 
      template == "ajax2" ? <AjaxFetcher mydata={mydata}  /> : 
      template == "ajax3" ? <AjaxFetcher mydata={mydata}  /> : 
      template == "ajax4" ? <AjaxFetcher mydata={mydata}  /> : 
      <SearchFetcher mydata={mydata}/>
    }
  </LanguageContext.Provider>

}

export default App;