import React from 'react';
import Aggregation  from './Aggregation/Aggregation'

function Aggregations({ data, aggregations, setAggregations })
{
  const aggs = data && data.searchres && data.searchres.aggregations;
  return aggs ? [...new Set( Object.keys(data.predefined).concat( Object.keys(aggs) ) )].map((k,i) =>   
        (<Aggregation key={"agg"+i} title={k} hit={aggs[k]}
          predefined={data.predefined && data.predefined[k]}
          aggregations={aggregations} setAggregations={setAggregations}/>)
    ) : "";
}


export default Aggregations;