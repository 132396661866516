import React, { useState, useEffect, useCallback } from 'react';
import '@material/react-list/dist/list.css';
import "@material/react-checkbox/dist/checkbox.css";
import '@material/react-card/dist/card.css';
import '@material/react-button/dist/button.css';
import Button from '@material/react-button';
import MaterialIcon from '@material/react-material-icon';
import Card, {
  CardPrimaryContent,
  CardActions,
} from "@material/react-card";
import '@material/react-layout-grid/dist/layout-grid.css';
import SearchRes from './SearchResults';
import I18N from '../I18N/I18n';

function InvertedResult({ mydata, data, query }) {
    const firsthits = data && data.invsearchres && data.invsearchres.hits && data.invsearchres.hits.hits.slice(0, 1).map(hit => (<SearchRes hit={hit}></SearchRes>));
  
    return data && data.invsearchres && data.invsearchres.hits && data.invsearchres.hits.max_score > data.searchres.hits.max_score + 25 ? (
          <Card className="invertlanguage">
            <CardPrimaryContent>
            <h3>
            {I18N("invert_language")}
            </h3>
            </CardPrimaryContent>
            <CardActions>
              <a href={ (mydata.locale=="en" ? mydata.config && mydata.config.searchpage : mydata.config && mydata.config.enSearchPage)+"?query=" + query}
              class="knapp ghost"><MaterialIcon icon={"search"} />
                {I18N("switch_language")}
              </a>
            </CardActions>

            {firsthits}            
          </Card>
      ) : "";
}

export default InvertedResult;