import React, { useState, useEffect, useCallback } from 'react';
import Button from '@material/react-button';
import '@material/react-button/dist/button.css';

import "@material/react-chips/dist/chips.css";

function Pagination({ data, page, setPage})
{
  useEffect(() => {
    window.scroll(0, 0);
  }, [page]);

  var pages = data && data.searchres && data.searchres.hits &&  data.searchres.hits.total && data.searchres.hits.total.value > 0 ?
    new Array( parseInt(  data.searchres.hits.total.value/20 ) ).fill(1).map(  (p,i) => i ) : null;
  
  return (<div class="pagination">
    {pages ? pages.slice(page>5 ? page-5 : 0, page+5).map( (p,i) => {
        return (
        <Button key={i} raised={page != p} onClick= { () => setPage(p) } > 
            {p+1}
        </Button>
        )
      }) : ""
    }</div>);

}

export default Pagination;