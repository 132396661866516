import React, { useState, useEffect, useCallback } from 'react';
import List, {
  ListItem, ListItemText, ListGroup,
  ListGroupSubheader
} from '@material/react-list';
import Checkbox from '@material/react-checkbox';
import I18N from '../../I18N/I18n';

function Aggregation({ title, hit, predefined, aggregations, setAggregations }) {
  const choosenags = hit && hit.buckets ? hit.buckets.map((x) => ({[x.key]: 1})) : [];
  const esags = aggregations[title] ? aggregations[title].filter(k=>k!="*").map((x) => ({[x]: 1})) : [];
  const predefinedags = predefined ? predefined.map((x) => ({[x]: 1}) ) : [];

    const dictionary = Object.assign({},
      ...choosenags,
      ...esags,
      ...predefinedags);

    const aggs = dictionary && Object.keys(dictionary).filter(k=>k!="*").sort().map((bck, i) => {
      const bckc = hit && hit.buckets && hit.buckets.find && hit.buckets.find( h => h.key==bck);
      const cnt = bckc && bckc.doc_count;
      return (
        <ListItem key={i} onClick={() => { setAggregations(title, bck) }}>
          <Checkbox edge="start" color="primary" checked={aggregations[title] && aggregations[title].indexOf(bck) != -1} tabIndex={-1}/>
          <ListItemText primaryText={I18N(bck)} />
            <span className="cnt">{
              aggregations[title] && aggregations[title].filter(k=>k!="*").length ?             
                aggregations[title].indexOf(bck) != -1 ? cnt : "?" : 
              cnt
            }</span>
        </ListItem>);
    });
  
    return Object.keys(dictionary).length ? (
      <ListGroup>
        <ListGroupSubheader tag='h2'>{I18N(title)} </ListGroupSubheader>  
        <List
          checkboxList
          selectedIndex={[]}
        >
          {aggs}
        </List>
      </ListGroup>
    ) : ("");
  }
  
  export default Aggregation;